import axios from 'axios'
import {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {useDepositStore} from './Deposit_Store'

export function ManualMatching(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let depositData = props.depositData
  let transData = props.transData
  let depositFilterBtn = props.depositFilterBtn

  //States
  const [formData, setFormData] = useState(Object)
  const [loading, setLoading] = useState(false)
  const [currentTransaction, setCurrentTransaction] = useState(Object)
  const [remark, setRemark] = useState('')

  //Zustand Store
  const setGetData = useDepositStore((state: any) => state.setGetData)

  function isDepositURL() {
    var pattern = /^\/deposit\/\d+$/
    var url = window.location.pathname // Get the current URL path

    return pattern.test(url)
  }

  //Search function: START
  //const keys = ['txnId'], txnId not in all data, so buggy
  const keys = ['txnId']

  function search(query: any) {
    if (query == '') {
      retrieveData(SISPAY_API, '/transaction/list', 'TransactionList')
    } else {
      let searchedResult = formData.filter((data: any) =>
        keys.some((key) => data[key].toString().toLowerCase().includes(query))
      )

      setFormData(searchedResult)
    }
  }
  //Search function: END

  function retrieveData(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
            byTxnId: String(props.depositData.submittedTxnId),
            action: 'search',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        setFormData(response.data.result[`${location}`])
      })
      .catch(function (error) {})
  }

  //GET PENDING TRANSACTION LIST
  useEffect(() => {
    if (props.depositData.submittedTxnId !== '') {
      retrieveData(SISPAY_API, '/transaction/list', 'TransactionList')
    } else {
      setFormData([])
    }
  }, [props.depositData.submittedTxnId])

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = firstIndex + itemsPerPage
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : ''
  //Pagincation

  function submitMatchRequest(currentTransaction: any, depositData: any) {
    let transId = parseInt(currentTransaction.id)
    let depositProcessId = parseInt(depositData.depositProcessId)
    axios
      .post(
        `${SISPAY_API}/cash/depositrequest/match`,
        {
          request: {
            session: session,
            transId: transId,
            depositProcessId: depositProcessId,
            remark: remark,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        if (isDepositURL() !== true) {
          depositFilterBtn.current.click()
        }
        alert(response.data.message)
        setLoading(false)
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function Table() {
    return (
      <div className='py-0'>
        <div className='table-responsive mb-5'>
          <table className='table table-row-bordered gy-7 gs-7 mt-0'>
            <thead className='position-sticky top-0 z-index-3'>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th>SMS ID</th>
                <th>Received DateTime</th>
                <th>Merchant</th>
                <th>Customer Code</th>
                <th>Wallet Code</th>
                <th>Amount</th>
                <th>Transaction Type</th>
                <th>Transaction Status</th>
                <th>Callback Status</th>
                <th>Transaction Fee</th>
                <th>TxnID</th>
                <th>Submitted Transaction ID</th>
                <th>Sender</th>
                <th>Source Wallet Account</th>
                <th>Agent Name</th>
                <th>Wallet Account No</th>
                <th>Deposit Request ID</th>
                <th>SMS Content</th>
                <th>Match Status</th>
                <th style={{position: 'sticky', right: '0px'}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0
                ? currentItems.map((formData: any) => {
                    return (
                      <tr
                        key={
                          Number(formData.id) +
                          Math.floor(Math.random() * (9999999999999 - 1 + 1)) +
                          1
                        }
                      >
                        <td>{String(formData.smsId)}</td>
                        <td>{formData.receivedDt}</td>
                        <td>
                          {formData.merchantName} - {formData.merchantCd}
                        </td>
                        <td>{formData.customerCd}</td>
                        <td>{formData.walletCd}</td>
                        <td>{formData.amount}</td>
                        <td>{formData.transType}</td>
                        <td>{formData.transSts}</td>
                        <td>{formData.callbackStatus}</td>
                        <td>{formData.txnFee}</td>
                        <td>{formData.txnId}</td>
                        <td>{formData.submittedTxnId}</td>
                        <td>{formData.senderReceiver}</td>
                        <td>{formData.srcWalletAccNo}</td>
                        <td>{formData.agentName}</td>
                        <td>{formData.walletAccNo}</td>
                        <td>{formData.depositReqId}</td>
                        <td>{formData.smsBody}</td>
                        <td>{formData.matchStatus}</td>
                        <td style={{position: 'sticky', right: '0px'}}>
                          <button
                            className='btn btn-primary'
                            data-bs-target='#exampleModalToggle2'
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                            onClick={() => {
                              setCurrentTransaction(formData)
                            }}
                          >
                            MATCH
                          </button>
                        </td>
                      </tr>
                    )
                  })
                : ''}
            </tbody>
          </table>
        </div>
        {/* Pagination START */}
        <div className='mt-5 d-flex justify-content-center align-items-center'>
          <span className='me-5'>Total: {formData.length}</span>
          <button
            className='btn btn-secondary p-2 me-2 w-100px h-30px'
            disabled={currentPage === 1}
            onClick={previousPage}
          >
            Previous
          </button>
          {currentPage} of {lastPage}
          <button
            className='btn btn-secondary p-2 ms-2 w-100px h-30px'
            disabled={currentPage === lastPage}
            onClick={nextPage}
          >
            Next
          </button>
          <select
            onChange={(e) => changePage(Number(e.target.value))}
            className='btn btn-secondary p-2 ms-2 w-100px'
          >
            {Array.from({length: lastPage}, (_, i) => (
              <option key={i + 1} value={i + 1}>
                Page {i + 1}
              </option>
            ))}
          </select>
        </div>
        {/* Pagination END */}
      </div>
    )
  }

  function ManualApproval() {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center fs-2'>
        <span className='mb-5'>No Record Found.</span>
        <Button
          data-bs-dismiss='modal'
          onClick={() => {
            approve()
          }}
        >
          Approve
        </Button>
      </div>
    )
  }

  function approve() {
    axios
      .post(
        `${SISPAY_API}/cash/depositrequest/approve`,
        {
          request: {
            session: session,
            id: depositData.id,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        if (response.data.code == '200') {
          alert('Approved Successfully')
          setGetData(true)
        } else {
          alert(response.data.message)
          setGetData(true)
        }
        if (isDepositURL() !== true) {
          depositFilterBtn.current.click()
        }
        setLoading(false)
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div>
      <div
        className='modal fade'
        data-bs-backdrop='static'
        id='exampleModalToggle'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-dialog-centered mw-75'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalToggleLabel'>
                STEP 1 : PENDING TRANSACTION
              </h5>
              <input
                type='text'
                placeholder='Search: TxnID'
                className='form-control bg-transparent w-25 ms-auto'
                onChange={(e) => search(e.target.value)}
              />
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {formData.length !== 0 ? <Table /> : <ManualApproval />}
            </div>

            <div className='modal-footer'></div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        data-bs-backdrop='static'
        id='exampleModalToggle2'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel2'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-dialog-centered mw-75'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title d-flex align-items-center' id='exampleModalToggleLabel2'>
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'
                  data-bs-target='#exampleModalToggle'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-3' />
                </button>
                Step 2 : Enter Remarks
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <textarea
                className='w-100 h-150px'
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
              />
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-success'
                data-bs-dismiss='modal'
                onClick={() => {
                  submitMatchRequest(currentTransaction, depositData)
                }}
              >
                MATCH
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='mx-10 mt-0 mb-10'>
        {/* <a
          className='btn btn-danger w-100'
          data-bs-toggle='modal'
          href='#exampleModalToggle'
          role='button'
        >
          MANUAL MATCHING
        </a> */}
      </div>
    </div>
  )
}
