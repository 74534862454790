import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function Export_PDF_Detailed_Report(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A1' as 'A1',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'Created DateTime',
              'Submitted DateTime',
              'Completed DateTime',
              'Merchant Name',
              'Merchant Code',
              'Customer Code',
              'Bank',
              'Amount',
              'Transaction Type',
              'Transaction Status',
              // 'Transaction Success By',
              'Fee',
              'Merchant Transaction ID',
              'Submitted Transaction ID',
              'Reference ID',
              'Source Account No',
              'Source Account Name',
              'Destination Account No',
              'Destination Account Name',
              'Match Status',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.receivedDT,
              item.submittedDT,
              item.completedDT,
              item.merchantName,
              item.merchantCd,
              item.customerCd,
              item.walletCd,
              item.amount,
              item.transType,
              item.status,
              item.fee,
              item.merchantTxnId,
              item.submittedTxnId,
              item.referenceId,
              item.srcWalletAcc,
              item.srcWalletName,
              item.destWalletAcc,
              item.destWalletName,
              item.matchStatus,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
