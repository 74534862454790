import axios from 'axios'
import {getCurrentDateTime} from '../../components/GetCurrentDatetime'
import {modifyDT} from '../../components/ModifyDT'
import {modifyKeys} from '../../components/ModifyKeys'

const keyMap = {
  id: 'ID',
  smsId: 'SMS ID',
  receivedDt: 'Received DateTime',
  merchantName: 'Merchant Name',
  merchantCd: 'Merchant Code',
  customerCd: 'Customer Code',
  walletCd: 'Wallet Code',
  amount: 'Amount',
  transType: 'Transaction Type',
  transSts: 'Transaction Status',
  callbackStatus: 'Callback Status',
  txnFee: 'Transaction Fee',
  txnId: 'TxnID',
  submittedTxnId: 'Submitted TxnID',
  senderReceiver: 'Sender Receiver',
  srcWalletAccNo: 'Source Wallet Acc No',
  agentName: 'Agent Name',
  walletAccNo: 'Wallet Acc No',
  depositReqId: 'Deposit Request ID',
  smsBody: 'SMS Content',
  matchStatus: 'Match Status',
  completedDT: 'Completed DateTime',
  invalidSrcWallet: 'Invalid Source Wallet',
  agentCommFee: 'Agent Commission Fee',
}

function escapeCsvValue(value: string): string {
  // If the value contains a comma or line breaks, wrap it in double quotes and escape existing double quotes and line breaks
  if (value.includes(',') || value.includes('\n') || value.includes('\r')) {
    return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
  }
  return value
}

function generateFile(formData: any) {
  let dataset = formData.map((obj: any) => {
    const updatedObj = {...obj}
    delete updatedObj.smsTxnDt
    // delete updatedObj.reqId
    return updatedObj
  })

  // Modify receivedDT
  dataset = modifyDT(dataset, 'Completed DateTime')
  dataset = modifyDT(dataset, 'Received DateTime')

  const header = Object.keys(dataset[0]).join(',')
  const rows = dataset.map((transaction: any) =>
    Object.values(transaction)
      .map((value: any) => escapeCsvValue(value.toString()))
      .join(',')
  )

  const csvContent = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvContent], {type: 'text/csv'})

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `transaction_${getCurrentDateTime()}.csv`
  a.click()

  URL.revokeObjectURL(url)
}

export default function Transaction_Export_CSV(filterValues: any, setDownloadLoading: any): void {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  console.log('props filter:', filterValues)

  setDownloadLoading(true)

  let search = filterValues.search
  let walletAccNo = filterValues.walletAccNo
  let transType = filterValues.transType
  let txnId = filterValues.txnId
  let dateTimeFrom = filterValues.dateTimeFrom
  let dateTimeTo = filterValues.dateTimeTo
  let merchant = filterValues.merchant
  let transSts = filterValues.transSts
  let callbackStatus = filterValues.callbackStatus
  let senderRecvr = filterValues.senderRecvr

  axios
    .post(
      `${SISPAY_API}/transaction/list`,
      {
        request: {
          session: session,
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
          action: 'download',
          // page: '1',

          //Filter
          ...(txnId && {byTxnId: txnId}),
          ...(search && {bySrcWalletAcc: search}),
          ...(walletAccNo && {byWalletAccNo: walletAccNo}),
          ...(transType && {byTransType: transType}),
          ...(merchant && {byMerchant: merchant}),
          ...(transSts && {byTransSts: transSts}),
          ...(callbackStatus && {byCallbackStatus: callbackStatus}),
          ...(senderRecvr && {bySenderRecvr: senderRecvr}),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = modifyKeys(response.data.result.TransactionList, keyMap)

      generateFile(formData)

      setDownloadLoading(false)
    })
    .catch(function (error) {
      setDownloadLoading(false)
    })
}
