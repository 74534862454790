import React, {Ref, useEffect, useState} from 'react'
import {Column, useFlexLayout, useResizeColumns, useTable} from 'react-table'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Monthly_Filter} from './components/Monthly_Filter'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {metricsArray} from '../../components/MetricsArray'
import {Export_PDF_Monthly_Report} from './components/Export_PDF_Monthly_Report'
import FormatNumber from '../../components/FormatNumber'
import {Monthly_Styles_NoAction} from './components/Monthly_Style'
import deleteKeysFromObjects from '../../components/DeleteKeysFromObjects'

const Monthly_Reporting_List: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [metrics, setMatrics] = useState<any>(undefined)

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Calculate Data
  function calculateSum(data: any, key: string) {
    return data.reduce(
      (acc: any, curr: any) => Number(acc) + (curr[key] ? Number(curr[key]) : 0),
      0
    )
  }

  function calculateAverage(data: any, key: string) {
    return data.reduce((acc: any, curr: any) => acc + curr[key], 0) / data.length
  }

  useEffect(() => {
    let metrics = localStorage.getItem('monthlyReportMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table::START
  interface Data {
    closingBalance: string
    commissionFee: string
    merchantCd: string
    merchantName: string
    month: string
    openingBalance: string
    totalDeposit: string
    totalJustpayFee: string
    totalSettlement: string
    totalTopup: string
    totalWithdrawal: string
    tDepositFee: string
    tWithdrawalFee: string
    tSettlementFee: string
    tTopUpFee: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: ' ',
        Cell: ({row, rows}: any) => {
          return <div>{row.index + 1}</div>
        },
        width: 60,
      },
      {
        Header: 'Merchant',
        accessor: (row) => {
          if (row.merchantName == '' || row.merchantCd === '') {
            return ''
          } else {
            return row.merchantName + ' - ' + row.merchantCd
          }
        },
      },
      {
        id: 'Month',
        Header: 'Month',
        accessor: 'month',
      },
      {
        id: 'Opening Balance',
        // Header: 'Opening Balance',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Opening Balance</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'openingBalance'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.openingBalance))
        },
      },
      {
        id: 'Commission Fee',
        // Header: 'Commission Fee',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Commission Fee</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'commissionFee'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.commissionFee))
        },
      },
      {
        id: 'Total Deposit',
        // Header: 'Total Deposit',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Total Deposit</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'totalDeposit'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalDeposit))
        },
      },
      {
        id: 'Deposit Fee',
        // Header: 'Deposit Fee',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Deposit Fee</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'tDepositFee'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.tDepositFee))
        },
      },

      {
        id: 'Total Withdrawal',
        // Header: 'Total Withdrawal',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Total Withdrawal</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'totalWithdrawal'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalWithdrawal))
        },
      },
      {
        id: 'Withdrawal Fee',
        // Header: 'Withdrawal Fee',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Withdrawal Fee</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'tWithdrawalFee'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.tWithdrawalFee))
        },
      },

      {
        id: 'Total Topup',
        // Header: 'Total Topup',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Total Topup</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'totalTopup'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalTopup))
        },
      },
      {
        id: 'Top up Fee',
        // Header: 'Top up Fee',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Top up Fee</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'tTopUpFee'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.tTopUpFee))
        },
      },

      {
        id: 'Total Settlement',
        // Header: 'Total Settlement',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Total Settlement</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'totalSettlement'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.totalSettlement))
        },
      },
      {
        id: 'Settlement Fee',
        // Header: 'Settlement Fee',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Settlement Fee</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'tSettlementFee'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.tSettlementFee))
        },
      },
      {
        id: 'Closing Balance',
        // Header: 'Closing Balance',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Closing Balance</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'closingBalance'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(Number(row.closingBalance))
        },
      },
    ],
    [currentItems]
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('monthlyReportMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.id)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5' style={{height: '70vh'}}>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200 sticky-header'
                    >
                      {headerGroup.headers.map((column: any) => (
                        <div {...column.getHeaderProps()} className='th'>
                          {column.render('Header')}
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <div
                            {...(column as any).getResizerProps()}
                            className={`resizer ${(column as any).isResizing ? 'isResizing' : ''}`}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  const keyMap = {
    merchantName: 'Merchant Name',
    merchantCd: 'Merchant Code',
    Month: 'Month',
    openingBalance: 'Opening Balance',
    commissionFee: 'Commission Fee',
    totalDeposit: 'Total Deposit',
    tDepositFee: 'Deposit Fee',
    totalWithdrawal: 'Total Withdrawal',
    tWithdrawalFee: 'Withdrawal Fee',
    totalTopup: 'Total Topup',
    tTopUpFee: 'Top up Fee',
    totalSettlement: 'Total Settlement',
    tSettlementFee: 'Settlement Fee',
    closingBalance: 'Closing Balance',
  }

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      deleteKeysFromObjects(formData, ['totalJustpayFee']),
                      keyMap,
                      startDate,
                      endDate,
                      'Monthly_Report'
                    )
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      deleteKeysFromObjects(formData, ['totalJustpayFee']),
                      keyMap,
                      startDate,
                      endDate,
                      'Monthly_Report'
                    )
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_bulk_deactive'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_PDF_Monthly_Report(formData, startDate, endDate)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
        </div>

        <div className='m-0 d-flex'>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Monthly_Filter
        setFormData={setFormData}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <Monthly_Styles_NoAction>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Monthly_Styles_NoAction>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default Monthly_Reporting_List
